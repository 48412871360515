<template>
    <el-dialog
        class="dialog-container"
        :visible.sync="dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="cancel"
        width="500px"
        title="项目通知"
        center>
        <div class="flex flex-direction">
            <div class="flex align-center">
                <div class="title" :class="{active:type === 3}" @click="type = 3">三方比价提醒</div>
                <div class="title" :class="{active:type === 1}" @click="type = 1">项目即将过期</div>
                <div class="title" :class="{active:type === 2}" @click="type = 2">项目已被锁定</div>
            </div>
            <div class="content flex flex-direction">
                <template v-if="type === 1">
                    <div class="item-info text-red" v-for="(item,index) in data.expire" :key="index">
                        {{ index + 1 }}.{{ item.name }}项目将于{{ item.limitedTime }}后被锁定，请尽快处理！
                    </div>
                    <el-empty class="self-center margin-top-sm"  image-size="100"  v-if="!data.expire.length"></el-empty>
                </template>
                <template v-if="type === 2">
                    <div class="item-info " v-for="(item,index) in data.locked" :key="index">
                        {{ index + 1 }}.{{ item.name }}
                    </div>
                    <el-empty class="self-center margin-top-sm"  image-size="100" v-if="!data.locked.length"></el-empty>
                </template>
                <template v-if="type === 3">
                    <div class="item-info flex flex-direction" v-for="(item,index) in project_bijia" :key="index">
                        <div v-if="item.day >= 0"> {{ index + 1 }}.{{ item.name }}会议将在{{item.day}}天后开始，请及时提交
                            三方比价手续。如会议开始前还未提交，项目将做锁定处理。</div>
                        <div v-else> {{ index + 1 }}.
                            {{ item.name }}会议已于{{ Math.abs(item.day) }}天前开始，请在{{ item.unlock_end_time }}前提交三方比价或写明不需三方比价的原因。如逾期，项目将再次锁定。
                        </div>
                        <el-button class="self-end" type="primary" size="mini" @click="noSangFang(item)">无需提供三方比价</el-button>
                    </div>
                    <el-empty class="self-center margin-top-sm"  image-size="100" v-if="!project_bijia.length"></el-empty>
                </template>
            </div>
            <div class="dialog-operate flex align-center justify-center">
                <el-button class="btn min round" type="info" @click="cancel">取消</el-button>
                <el-button class="btn min round" type="primary" :loading="loading" @click="noRemind">
                    我已知悉
                </el-button>
            </div>
        </div>
        <add-reason-dialog :dialog="addReasonDialog" :id="cur_id" @watchState="watchState"></add-reason-dialog>
    </el-dialog>
</template>

<script>

import AddReasonDialog from "./AddReasonDialog";
export default {
    name: "ShowProjectInfoDialog",
    components: {AddReasonDialog},
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        data: { //类型
            type: [Object, String],
        }
    },
    data() {
        return {
            type: 3,
            loading: false,
            cur_id :'',
            addReasonDialog:false
        }
    },
    computed: {
        project_bijia(){
            return this.data.project_bijia.filter(f=>{ return !f.hide})
        }
    },
    created() {
    },
    methods: {
        noSangFang(item){
            this.cur_id = item.id
            this.addReasonDialog = true
        },
        watchState(data){
            if (data) {
                let findIndex = this.data.project_bijia.findIndex(f=>{ return f.id === this.cur_id});
                let _data = this.data.project_bijia[findIndex]
                _data.hide = true
                this.data.project_bijia.splice(findIndex,1,_data)
            }
            this.addReasonDialog = false;
        },
        noRemind() {
            this.$emit('watchState', true)
        },
        cancel() {
            this.$emit('watchState', false)
        }
    },
    watch: {}
}
</script>

<style scoped lang="scss">
.content {
    height: 500px;
    overflow-y: auto;
}

.title {
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    + .title {
        margin-left: 20px;
    }

    &.active {
        color: #3b77e7;
    }
}

.item-info {
    line-height: 1.5;
    font-size: 14px;
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eeeeee;
}
</style>