<template>
    <div class="home">
        <headerDiv :newsCount="newsCount" class="header"/>
        <div class="info">
            <muneDiv :countApprovalData="countApprovalData" :countProjectData="countProjectData" :daiban="daiban" :copyfor="copyfor" :navIndex="navIndex"
                     class="left-mune"/>
            <div class="right-info">
                <keep-alive :max="3">
                    <router-view v-if="$route.meta.keepAlive" :key="$route.query.t" @setIndex="getIndex"></router-view>
                </keep-alive>
                <router-view v-if="!$route.meta.keepAlive" :key="$route.query.t" @setIndex="getIndex"></router-view>
                <footerDiv/>
            </div>
        </div>
        <show-project-info-dialog :dialog="showProjectInfoDialog" :data="cur_data" @watchState="watchState"></show-project-info-dialog>
    </div>
</template>

<script>
import headerDiv from "./components/headerDiv";
import muneDiv from "./components/muneDiv";
import footerDiv from "./components/footerDiv";
import ShowProjectInfoDialog from "./components/ShowProjectInfoDialog";

export default {
    name: 'Home',
    provide() { // 注册一个方法
        return {
            reload: this.reload
        }
    },
    data() {
        return {
            navIndex: [],
            countApprovalData: 0,
            countProjectData: 0,
            newsCount: 0,
            daiban: 0,
            copyfor: 0,
            isRouterAlive: true,
            timer: '',

            cur_data:'',
            showProjectInfoDialog: false,
        }
    },
    created() {
        let that = this
        that.init()
        that.getNotice()
        this.timer = setInterval(function () {
            //执行内容
            that.init()
        }, 8000);
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    methods: {
        getIndex(index) {
            this.navIndex = index
        },
        reload() {
            this.isRouterAlive = false
            this.$nextTick(function () {
                this.isRouterAlive = true
            })
        },
        getNotice() {
            this.$api.apiContent
                .getProjectattachedNotice({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    if (res.data.is_show && res.data.count) {
                        this.cur_data = res.data.data
                        this.showProjectInfoDialog = true
                        // this.$confirm(`<h3>项目即将过期：</h3>${
                        //     res.data.data.expire.map((item,index) => {
                        //         return `<i style="color: red; line-height: 1.5;">${index + 1}.${item.name}项目将于${item.limitedTime}后被锁定，请尽快处理！</i> `
                        //     }).join('\n')
                        // }<h3>项目已被锁定：</h3>${
                        //     res.data.data.locked.map((item,index) => {
                        //         return `<i style="color: black; line-height: 1.5;">${index + 1}.${item.name}</i> `
                        //     }).join('\n')
                        // }`, '项目通知', {
                        //     confirmButtonText: '我已知悉',
                        //     cancelButtonClass: "big-btn",
                        //     confirmButtonClass: "big-btn",
                        //     customClass: "notice",
                        //     dangerouslyUseHTMLString: true
                        // }).then(() => {
                        //     this.noRemind()
                        // })
                    }

                })

        },
        watchState(data) {
            if (data) this.noRemind()
            this.showProjectInfoDialog = false
        },
        init() {
            this.$api.apiContent
                .getUnreadNum({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.countApprovalData = res.shenpi;
                    this.daiban = res.daiban;
                    this.countProjectData = res.infor;
                    this.newsCount = res.count;
                    this.copyfor = res.copyfor;
                })
                .catch(() => {
                    this.countApprovalData = 0;
                    this.countProjectData = 0;
                    this.newsCount = 0;
                    this.copyfor = 0;
                });

        },
        noRemind() {
            this.$api.apiContent
                .noticeStop({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                        },
                        this.$store.state.user.key
                    ),
                })
        }
    },
    components: {
        ShowProjectInfoDialog,
        headerDiv,
        muneDiv,
        footerDiv
    }
}
</script>

<style lang="scss" scoped>
.home {
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*bottom: 0;*/
    width: 100%;
    min-width: 1200px;
    background: #f5f5f5;

    .header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background-color: #3b77e7;
    }

    .info {
        /*position: fixed;*/
        /*top: 80px;*/
        /*left: 0;*/
        /*bottom: 0;*/
        width: 100%;
        padding-top: 60px;
        /*overflow-y: scroll;*/

        .left-mune {
            position: fixed;
            top: 80px;
            left: 0;
            bottom: 0;
            width: 301px;
            background-color: #33394f;
            z-index: 9;
        }

        .right-info {
            margin-left: 301px;
            min-width: 1200px;
            z-index: 9;
        }
    }
}
</style>
