<template>
    <div class="heade flex-center between-box">
        <b>学会项目执行系统</b>
        <div class="flex-center between-box head-right">
            <span>欢迎 {{ user_name }} 登录系统！</span>
            <el-badge class="item" :value="newsCount" :hidden="newsCount === 0">
                <router-link to="/home/systemMsg" class="head-msg flex-center"
                ><i class="el-icon-bell"></i>消息
                </router-link>
            </el-badge>

            <a href="javascript:void 0" class="flex-center" @click="sginOut">
                <img src="../../assets/img/close.png" alt=""/>退出</a
            >
        </div>
    </div>
</template>

<script>
    export default {
        name: "headerDiv",
        data() {
            return {
                user_name: this.$store.state.user.true_name,
            };
        },
        props: {
            newsCount: {
                type: Number,
            },
        },
        methods: {
            init() {

            },
            sginOut() {
                this.$confirm("您确定要退出吗？", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    cancelButtonClass: "big-btn",
                    confirmButtonClass: "big-btn",
                    center: true,
                })
                    .then(() => {
                        this.$message({
                            type: "success",
                            message: "退出成功!",
                        });
                        this.$store.state.user = null;

                        this.$router.push("/login");
                    })
                    .catch();
            },
        },
    };
</script>

<style scoped lang="scss">
    .heade {
        padding: 0 50px;
        z-index: 10;
        b {
            font-size: 32px;
            letter-spacing: 2px;
            color: #ffffff;
        }
        .head-right {
            /*width: 400px;*/
            span {
                font-size: 18px;
                color: #ffffff;
            }
            .item {
                /*margin-top: 10px;*/
                margin-right: 40px;
                .head-msg {
                    font-size: 18px;
                    color: #ffffff;
                    i {
                        font-size: 20px;
                        font-weight: bold;
                        margin-right: 5px;
                    }
                }
            }

            a {
                font-size: 18px;
                color: #ffffff;
                img {
                    width: 20px;
                    margin-right: 5px;
                }
            }
        }
    }
</style>
