<template>
  <div class="footer">北京乳腺病防治学会、北京癌症防治学会 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备19017650号-1</a></div>
</template>

<script>
  export default {
    name: "footerDiv"
  }
</script>

<style scoped lang="scss">
.footer{
  /*position: absolute;*/
  /*left: 320px;*/
  /*right: 0;*/
  /*bottom: 0;*/
  height: 60px;
  padding-left: 40px;
  line-height: 60px;
  background: #f5f5f5;
  a{
    color: #333;
  }
}
</style>