<template>
    <el-dialog
        class="dialog-container"
        :visible.sync="dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="cancel"
        append-to-body
        title="请输入原因"
        width="400px"
        center>
        <div class="flex flex-direction">
            <el-form class="ruleForm" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
                <el-form-item prop="remark">
                    <el-input v-model="ruleForm.remark" type="textarea" :rows="3" resize="none" placeholder="请输入备注">
                    </el-input>
                </el-form-item>
            </el-form>
            <div class="dialog-operate flex align-center justify-center">
                <el-button class="btn min round" type="info" @click="cancel">取消</el-button>
                <el-button class="btn min round" type="primary" :loading="loading" @click="submitForm('ruleForm')">
                    保存
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>

export default {
    name: "AddReasonDialog",
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        id: { //详情ID
            type: [Number,String],
        }
    },
    data() {
        return {
            loading: false,
            ruleForm: {
                remark: '',
            },
            rules: {
                remark: [
                    {required: true, message: '请输入', trigger: 'blur'}
                ],

            },
        }
    },
    created() {
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.addbubijia();
                }
            });
        },
        async addbubijia() {
            this.$api.apiContent
                .addbubijia({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    project_id: this.id,
                    reason: this.ruleForm.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            project_id: this.id,
                            reason: this.ruleForm.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then(() => {
                    this.$emit('watchState', true)
                    this.$message.success('提交成功');
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        clearContent() {
            this.$refs.ruleForm.resetFields()
        },
        cancel() {
            this.clearContent()
            this.$emit('watchState', false)
        }
    },
    watch: {

    }
}
</script>

<style scoped lang="scss">

</style>